import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { Environment } from "./configProvider";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth = localStorage.getItem('auth');
    options.headers.set('Authorization', `Basic ${auth}`);
    options.mode =  'cors'
    return fetchUtils.fetchJson(url, options);
};

const adminAPI = `${Environment.API}/admin`;

const dataProvider = {
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        let query = {
            pageNumber: page,
            pageSize: perPage
        };
        if (params.filter.q) {
            query.q = params.filter.q;
        }

        let url = `${adminAPI}/${resource}?${stringify(query)}`;

        if (resource === 'trips' && params.filter.userId) {
            // Special case for retrieving user trips
            const { data: userData } = await httpClient(`${adminAPI}/users/${params.filter.userId}`)
                .then(({ json }) => ({
                    data: json
                }));

            return httpClient(`${adminAPI}/users/${params.filter.userId}/trips`).then(({ json }) => ({
                data: json === undefined || json === null ? [] : json.map(item => ({
                    ...item,
                    id: item.id,
                    isPaged: false,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    supportId: userData.supportId
                })),
                total: parseInt(json.length, 10)
            }));
        }
        else if (resource === 'leaderboard') {
            return httpClient(`${adminAPI}/leaderboard-setting`)
                .then(({ json }) => ({
                    data: [{...json, id: 1}],
                    total: 1
                }));
        }
        else if (resource === 'organisation-category-all') {
            return httpClient(`${adminAPI}/organisation-category-all`)
                .then(({ json }) => ({
                    data: json,
                    total: 1
                }))
        }

        return httpClient(url).then(({ headers, json }) => ({
            data: json === undefined || json === null ? [] : json.map(item => ({
                ...item,
                id: resource === 'vouchers/redeemed' ? item.itemId : item.id,
                isPaged: headers.has('content-range')
            })),
            total: parseInt(headers.has('content-range') ? headers.get('content-range').split('/').pop() : json.length, 10),
        }));
    },

    getOne: async (resource, params) => {
        if (resource === 'system-notification') {
            const url = `${adminAPI}/system-notification`;
            return httpClient(url).then(({ json }) => ({
                data: json,
            }));
        }
        let url = `${adminAPI}/${resource}/${params.id}`;

        if (resource === 'trips') {
            const { data: tripData } = await httpClient(`${url}/data`)
                .then(({ json }) => ({
                    data: json
                }));

            const { data: tripDataOriginal } = await httpClient(`${url}/original-data`)
                .then(({ json }) => ({
                    data: json
                }));

            return httpClient(url).then(({ json }) => ({
                data: { ...json, data: tripData, originalData: tripDataOriginal }
            }));
        }
        else if (resource === 'organisations') {
            const { data: orgVouchers } = await httpClient(`${adminAPI}/organisations/${params.id}/vouchers`)
                .then(({ json }) => ({
                    data: json
                }));

            const organisationCategory = await httpClient(`${adminAPI}/organisation-category-all`)
                .then(({ json }) => ({
                    data: json,
                }))

            return httpClient(`${adminAPI}/organisations/${params.id}`).then(({ json }) => ({
                data: { ...json, vouchers: orgVouchers, organisationCategory }
            }));
        }
        else if (resource === 'leaderboard') {
            return httpClient(`${adminAPI}/leaderboard-setting`)
                .then(({ json }) => ({
                    data: {...json, id: 1}
                }));
        }
        else if (resource === 'vouchers' || resource === 'voucher-items' || resource === 'voucher-items-batch') {
            const { data: voucherItems } = await httpClient(`${adminAPI}/vouchers/${params.id}/items`)
                .then(({ json }) => ({
                    data: json
                }));

            return httpClient(`${adminAPI}/vouchers/${params.id}`).then(({ json }) => ({
                data: { ...json, items: voucherItems }
            }));
        }
        else if (resource === 'voucher-items-edit') {
            url = `${adminAPI}/voucher-items/${params.id}`;
        }

        return httpClient(url).then(({ json }) => ({
            data: json,
        }));
    },

    getMany: async (resource, params) => {
        // Only one ID, so 'getOne' and return
        if (params && params.ids && params.ids.length === 1) {
            return httpClient(`${adminAPI}/${resource}/${params.ids[0]}`).then(({ json }) => ({ data: [json] }));
        }

        // Multiple IDs, 'getList' and filter
        const query = {
            //filter: JSON.stringify({ id: params.ids }),
            pageNumber: 1,
            pageSize: 100000
        };
        const url = `${adminAPI}/${resource}?${stringify(query)}`;
        const response = await httpClient(url).then(({ json }) => ({ data: json }));
        if (params && params.ids) {
            return Promise.resolve({ data: response.data.filter(x => params.ids.includes(x.id)) });
        }
        return Promise.resolve(response);
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${adminAPI}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: async (resource, params) => {
        let url = `${adminAPI}/${resource}/${params.id}`;
        let payload = params.data;

        if (resource === 'voucher-items') {
            url = `${adminAPI}/vouchers/${params.id}/items`;
            for (const code of params.data.codes) {
                await httpClient(url, {
                    method: 'POST',
                    body: JSON.stringify(code),
                }).then(({ json }) => ({ data: json }));
            }
            return Promise.resolve({ data: { id: params.id }});
        }
        else if (resource === 'voucher-items-batch') {
            url = `${adminAPI}/vouchers/${params.id}/items/batch`;
            let { files, ...inputJson } = payload;
            
            // Multi-part upload
            const formData = new FormData();
            formData.append('validate', inputJson.validate);
            formData.append('itemListFile', files.rawFile);

            return httpClient(url, {
                method: 'POST',
                body: formData,
            }).then(({ json }) => ({
                data: { ...json, id: params.id, validate: inputJson.validate },
            }));
        }
        else if (resource === 'organisations') {
            let { image, heroImage, avatarImage, ...json } = payload;

            const organisationJson = {
                name: json.name,
                description: json.description,
                category: json.category,
                categoryId: json.categoryId,
                isPremium: json.isPremium,
                isFeatured: json.isFeatured,
                imageUrl: json.imageUrl,
                heroImageUrl: json.heroImageUrl,
                avatarUrl: json.avatarUrl,
                termsConditions: json.termsConditions,
            };

            // Multi-part upload
            const formData = new FormData();
            formData.append('organisationJson', JSON.stringify(organisationJson));
            if (image)
                formData.append('organisationImage', image.rawFile);
            if (heroImage)
                formData.append('organisationHeroImage', heroImage.rawFile);
            if (avatarImage)
                formData.append('organisationAvatarImage', avatarImage.rawFile);

            return httpClient(url, {
                method: 'PUT',
                body: formData,
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'news') {
            let { attachment, ...json } = payload;

            const newsJson = {
                name: json.name,
                description: json.description,
                link: json.link,
                readMin: json.readMin,
                isActive: json.isActive,
                isFeatured: json.isFeatured,
                priority: json.priority
            };

            // Multi-part upload
            const formData = new FormData();
            formData.append('newsJson', JSON.stringify(newsJson));
            if (attachment)
                formData.append('newsAttachment', attachment.rawFile);

            return httpClient(url, {
                method: 'PUT',
                body: formData,
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'banner') {
            const { attachment, ...json } = payload;

            const bannerJson = {
                name: json.name,
                description: json.description,
                link: json.link,
                priority: json.priority
            };

            // Multi-part upload
            const formData = new FormData();
            formData.append('bannerJson', JSON.stringify(bannerJson));
            if (attachment)
                formData.append('bannerAttachment', attachment.rawFile);

            return httpClient(url, {
                method: 'PUT',
                body: formData,
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'leaderboard') {
            const { file, ...json } = payload;
            const setting = {
                header: json.header,
                body: json.body,
                column1: json.column1,
                column2: json.column2,
                column3: json.column3,
                bannerTitle: json.bannerTitle,
                bannerDescription: json.bannerDescription,
                bannerLink: json.bannerLink,
            };
            const formData = new FormData();
            formData.append('settingJson', JSON.stringify(setting));
            if (file)
                formData.append('leaderboardFile', file.rawFile);

            return httpClient(`${adminAPI}/leaderboard-upload`, {
                method: 'PUT',
                body: formData,
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'organisation-category') {
            return httpClient(`${adminAPI}/organisation-category/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    type: payload.type.toUpperCase(),
                    name: payload.name.toUpperCase()
                }),
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'vouchers') {
            let { image, ...json } = payload;

            const entries = json.type !== 'GIVE_AWAY' ? null : (json?.entries || null)
            const voucherJson = {
                name: json.name,
                organisationId: json.organisationId,
                description: json.description,
                termsConditions: json.termsConditions,
                points: json.points,
                entries,
                isFeatured: json.isFeatured,
                isPremium: json.isPremium,
                type: json.type,
                url: json.url,
                validFrom: json.validFrom,
                validTo: json.validTo
            };

            // Multi-part upload
            const formData = new FormData();
            formData.append('voucherJson', JSON.stringify(voucherJson));
            if (image)
                formData.append('voucherImage', image.rawFile);

            return httpClient(url, {
                method: 'PUT',
                body: formData,
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'update-transaction-status') {
            url = `${adminAPI}/transactions/${params.id}/status`;
        }

        return httpClient(url, {
            method: 'PUT',
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: { ...json, id: params.id } }));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${adminAPI}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        let url = `${adminAPI}/${resource}`;
        let payload = params.data;
        if (resource === 'notifications') {
            if (params.data.type === 'ALL_USERS_SYSTEM_MESSAGE') {
                url = `${adminAPI}/${resource}/all`;
                payload = {
                    message: params.data.message,
                    url: params.data.url,
                    title: params.data.title,
                    category: params.data.category,
                };
            }
            else {
                payload = {
                    message: params.data.message,
                    userId: params.data.userId,
                    title: params.data.title,
                    category: params.data.category,
                };
            }
        }
        else if (resource === 'notifications/group') {
            let { files, ...inputJson } = payload;
            
            // Multi-part upload
            const formData = new FormData();
            formData.append('message', inputJson.message);
            formData.append('validate', inputJson.validate);
            formData.append('userListFile', files.rawFile);

            return httpClient(url, {
                method: 'POST',
                body: formData,
            }).then(({ json }) => ({
                data: { ...json, id: 1, validate: inputJson.validate },
            }));
        }
        else if (resource === 'organisations') {
            let { image, heroImage, avatarImage, ...organisationJson } = payload;

            // Multi-part upload
            const formData = new FormData();
            formData.append('organisationJson', JSON.stringify(organisationJson));
            formData.append('organisationImage', image.rawFile);
            formData.append('organisationHeroImage', heroImage?.rawFile);
            formData.append('organisationAvatarImage', avatarImage?.rawFile);
            return httpClient(url, {
                method: 'POST',
                body: formData,
            }).then(() => ({
                data: { ...params.data, id: 1 },
            }));
        }
        else if (resource === 'organisation-category') {
            return httpClient(`${adminAPI}/organisation-category`, {
                method: 'POST',
                body: JSON.stringify({
                    type: payload.type.toUpperCase(),
                    name: payload.name.toUpperCase()
                }),
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'vouchers') {
            let { image, ...voucherJson } = payload;

            // Multi-part upload
            const formData = new FormData();
            formData.append('voucherJson', JSON.stringify(voucherJson));
            formData.append('voucherImage', image.rawFile);

            return httpClient(url, {
                method: 'POST',
                body: formData,
            }).then(() => ({
                data: { ...params.data, id: 1 },
            }));
        }
        else if (resource === 'news') {
            let { attachment, ...newsJson } = payload;

            // Multi-part upload
            const formData = new FormData();
            formData.append('newsJson', JSON.stringify(newsJson));
            formData.append('newsAttachment', attachment.rawFile);

            return httpClient(url, {
                method: 'POST',
                body: formData,
            }).then(() => ({
                data: { ...params.data, id: 1 },
            }));
        }
        else if (resource === 'banner') {
            let { attachment, ...bannerJson } = payload;

            // Multi-part upload
            const formData = new FormData();
            formData.append('bannerJson', JSON.stringify(bannerJson));
            if(attachment) {
                formData.append('bannerAttachment', attachment.rawFile);
            }

            return httpClient(url, {
                method: 'POST',
                body: formData,
            }).then(() => ({
                data: { ...params.data, id: 1 },
            }));
        }
        else if (resource === 'introductory') {
            let { video } = payload;
            const formData = new FormData();
            if (video)
                formData.append('introductoryVideo', video.rawFile);

            return httpClient(`${adminAPI}/introductory-video`, {
                method: 'POST',
                body: formData,
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'reward-banner') {
            let { winImage, shopImage } = payload;
            const formData = new FormData();
            if (winImage) {
                formData.append('winBanner', winImage.rawFile);
            }
            if (shopImage) {
                formData.append('shopBanner', shopImage.rawFile);
            }

            return httpClient(`${adminAPI}/reward-banner`, {
                method: 'POST',
                body: formData,
            }).then(({ json }) => ({ data: { ...json, id: params.id } }));
        }
        else if (resource === 'system-notification') {
            url = `${adminAPI}/system-notification`;
        }

        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(payload),
        }).then(() => ({
            data: { ...params.data, id: 1 },
        }));
    },

    delete: async (resource, params) => {
        let url = `${adminAPI}/${resource}/${params.id}`;

        if (resource === 'voucher-items') {
            url = `${adminAPI}/vouchers/${params.previousData.voucherId}/items/${params.id}`;
        }

        return httpClient(url, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${adminAPI}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    }
};

export default dataProvider;
