import { Buffer } from 'buffer';
import { Environment } from "./configProvider";

const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        // Get the basic auth header value
        const auth = Buffer.from(`${username}:${password}`).toString('base64');

        // Perform a login request with the basic auth credentials
        const request = new Request(`${Environment.API}/auth/login`, {
            method: 'POST',
            headers: new Headers({ 'Authorization': `Basic ${auth}` }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error('Invalid username or password. Please try again.');
                }
                localStorage.setItem('auth', auth);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default authProvider;