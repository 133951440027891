import * as React from "react";
import { Admin, Resource } from 'react-admin';
import { KSafeLogin, KSafeLayout } from './Layout';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import dashboard from './views/dashboard'
//import { OrganisationList, OrganisationShow, OrganisationEdit, OrganisationCreate } from './views/organisations';
import { UserList, UserShow, UserEdit } from './views/users';
import { TripList, TripShow } from './views/trips';
import { TransactionList, TransactionShow, TransactionCreate, TransactionEdit } from './views/transactions';
import { NotificationList, NotificationShow, NotificationCreate, NotificationImportGroup } from './views/notifications';
//import { RedeemedVoucherList, VoucherList, VoucherShow, VoucherCreate, VoucherEdit, VoucherItemCreate, VoucherItemImport } from './views/vouchers';
import { NotificationPopUp } from './views/system-notification';
import { UpdateIntroductory } from "./views/introductory";
import { ImportLeaderboard, LeaderboardList, LeaderboardShow } from "./views/leaderboard";
import { WithdrawalsList} from "./views/withdrawals";
//import { OrganisationCategoryCreate, OrganisationCategoryEdit, OrganisationCategoryList, OrganisationCategoryShow } from "./views/organisation-category";

import UserIcon from '@mui/icons-material/Group';
import TripIcon from '@mui/icons-material/DirectionsCar';
import TransactionIcon from '@mui/icons-material/Redeem';
import NotificationIcon from '@mui/icons-material/Notifications';
//import OrganisationIcon from '@mui/icons-material/CorporateFare';
//import VoucherIcon from '@mui/icons-material/Discount';
//import RedeemedVoucherIcon from '@mui/icons-material/Loyalty';
import CampaignIcon from '@mui/icons-material/Campaign';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AirplayIcon from '@mui/icons-material/Airplay';
//import CategoryIcon from '@mui/icons-material/Category';*/}
import PhotoIcon from '@mui/icons-material/Photo';
import WithdrawalsIcon from '@mui/icons-material/LocalAtm';
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "./views/news";
import { BannerCreate, BannerEdit, BannerList, BannerShow } from "./views/banner";
import { UpdateRewardBanner } from "./views/reward-banner";

const App = () => (
    <Admin loginPage={KSafeLogin} layout={KSafeLayout} dashboard={dashboard} authProvider={authProvider} dataProvider={dataProvider}>
        <Resource
            name="users"
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            icon={UserIcon}
            options={{ label: 'Users' }}
        />
        <Resource
            name="trips"
            list={TripList}
            show={TripShow}
            icon={TripIcon}
            options={{ label: 'Trips' }}
        />
        <Resource
            name="transactions"
            list={TransactionList}
            show={TransactionShow}
            create={TransactionCreate}
            edit={TransactionEdit}
            icon={TransactionIcon}
            options={{ label: 'Transactions' }}
        />
        <Resource
            name="notifications"
            list={NotificationList}
            show={NotificationShow}
            create={NotificationCreate}
            icon={NotificationIcon}
            options={{ label: 'Notifications' }}
        />
        {/* 
        
        Hide the Organisation & Organisation Category screens
        <Resource
            name="organisation-category"
            list={OrganisationCategoryList}
            show={OrganisationCategoryShow}
            create={OrganisationCategoryCreate}
            edit={OrganisationCategoryEdit}
            icon={CategoryIcon}
            options={{ label: 'Organisation category' }}
        />
        <Resource
            name="organisations"
            list={OrganisationList}
            show={OrganisationShow}
            create={OrganisationCreate}
            edit={OrganisationEdit}
            icon={OrganisationIcon}
            options={{ label: 'Organisations' }}
        />

        Hide/Remove Vouchers and RedeemedVoucher screens
        <Resource
            name="vouchers"
            list={VoucherList}
            show={VoucherShow}
            create={VoucherCreate}
            edit={VoucherEdit}
            icon={VoucherIcon}
            options={{ label: 'Vouchers' }}
        />
        <Resource
            name="vouchers/redeemed"
            list={RedeemedVoucherList}
            icon={RedeemedVoucherIcon}
            options={{ label: 'Redeemed Vouchers' }}
        />
        <Resource
            name="voucher-items"
            edit={VoucherItemCreate}
        />
        <Resource
            name="voucher-items-batch"
            edit={VoucherItemImport}
        />
        */}
        <Resource
            name="news"
            list={NewsList}
            show={NewsShow}
            create={NewsCreate}
            edit={NewsEdit}
            icon={NewspaperIcon}
            options={{ label: 'News' }}
        />
        <Resource
            name="banner"
            list={BannerList}
            show={BannerShow}
            create={BannerCreate}
            edit={BannerEdit}
            icon={AirplayIcon}
            options={{ label: 'Sponsor banners' }}
        />
         <Resource
             name={'system-notification'}
             list={NotificationPopUp}
             icon={CampaignIcon}
             options={{ label: 'Pop-up notification' }}
         />
         <Resource
             name={'introductory'}
             list={UpdateIntroductory}
             create={UpdateIntroductory}
             icon={PlayCircleIcon}
             options={{ label: 'Introductory' }}
         />
         <Resource
             name={'leaderboard'}
             list={LeaderboardList}
             show={LeaderboardShow}
             edit={ImportLeaderboard}
             icon={LeaderboardIcon}
             options={{ label: 'Leaderboard' }}
         />
         <Resource
             name={'reward-banner'}
             list={UpdateRewardBanner}
             create={UpdateRewardBanner}
             icon={PhotoIcon}
             options={{ label: 'Reward banners' }}
         />
         <Resource
             name="withdrawals"
             list={WithdrawalsList}
             icon={WithdrawalsIcon}
             options={{ label: 'Cash Withdrawals' }}
         />

        {/* TODO enable once GET /voucher-items/{voucherItemId} endpoint is created
        <Resource
            name="voucher-items-edit"
            edit={VoucherItemEdit}
        />*/}
        <Resource
            name="notifications/group"
            create={NotificationImportGroup}
        />
    </Admin>
);

export default App;
