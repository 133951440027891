import * as React from "react";
import { TableHead, TableRow, TableCell } from '@mui/material';
import { 
    List, Datagrid, Pagination,
    Show, TabbedShowLayout, Tab, TextField, DateField, FunctionField, ReferenceField,
    ReferenceInput, AutocompleteInput,
    TopToolbar, ExportButton, DeleteWithConfirmButton, useRecordContext, useListContext
} from 'react-admin';
import { JsonField } from "react-admin-json-view";
import { GoogleMap, useJsApiLoader, MarkerF, PolylineF } from '@react-google-maps/api';
import { Environment } from "../providers/configProvider";

const styles = {
    cellRight: {
        textAlign: 'right'
    },
    cellBorder: {
        borderLeft: '1px solid #000',
        textAlign: 'right'
    },
    topHeader: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    topHeaderBorder: {
        fontWeight: 'bold',
        textAlign: 'center',
        borderLeft: '1px solid #000'
    }
};

const TripListHeader = ({ children }) => (
    <TableHead>
        <TableRow>
            <TableCell colSpan={5} />
            <TableCell colSpan={3} sx={styles.topHeaderBorder}>Trip Data</TableCell>
            <TableCell colSpan={4} sx={styles.topHeaderBorder}>Safety Score</TableCell>
            <TableCell sx={styles.topHeaderBorder} />
        </TableRow>
        <TableRow>
            {React.Children.map(children, child => (
                <TableCell key={child.props.source} sx={['distanceMetres','safetyScoreSpeed','status'].includes(child.props.source) ? styles.cellBorder : null}>
                    {child.props.label}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

export const TripList = () => {
    const ListActions = () => (
        <TopToolbar>
            <ExportButton maxResults={10000} />
        </TopToolbar>
    );

    const ListPagination = () => {
        const { data } = useListContext();
        if (data && data.length > 0 && data[0].isPaged === false) return null;
        return <Pagination rowsPerPageOptions={[5, 10, 25, 50]} />;
    };

    const listFilters = [
        <ReferenceInput label="User" source="userId" reference="users" enableGetChoices={({ q }) => q !== undefined && q.length >= 1} perPage={2147483647} sort={{ field: 'lastName', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={record => `${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} sx={{ width: 600 }} />
        </ReferenceInput>
    ];

    return (
        <>
            <List actions={<ListActions />} filters={listFilters} pagination={<ListPagination />} perPage={25}>
                <Datagrid rowClick="show" header={<TripListHeader />} bulkActionButtons={false} sx={{
                    '& .column-pointsEarned': styles.cellRight,
                    '& .column-distanceMetres': styles.cellBorder,
                    '& .column-averageSpeedKmh': styles.cellRight,
                    '& .column-co2EmissionGrams': styles.cellRight,
                    '& .column-safetyScoreSpeed': styles.cellBorder,
                    '& .column-safetyScoreDistraction': styles.cellRight,
                    '& .column-safetyScoreFatigue': styles.cellRight,
                    '& .column-safetyScoreOverall': styles.cellRight,
                    '& .column-status': styles.cellBorder
                }}>
                    <FunctionField label="User" render={record =>`${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                    <TextField label="Name" source="name" />
                    <DateField label="Started" source="startedAt" showTime locales="sv-SE" />
                    <DateField label="Ended" source="endedAt" showTime locales="sv-SE" />
                    <TextField label="Points Earned" source="pointsEarned" />
                    <FunctionField label="Distance" source="distanceMetres" render={record => `${Number(record.distanceMetres).toLocaleString()} m`} />
                    <FunctionField label="Average Speed" source="averageSpeedKmh" render={record => `${record.averageSpeedKmh} km/h`} />
                    <FunctionField label="CO2 Omissions" source="co2EmissionGrams" render={record => `${Number(record.co2EmissionGrams).toLocaleString()} g`} />
                    <TextField label="Speed" source="safetyScoreSpeed" />
                    <TextField label="Distraction" source="safetyScoreDistraction" />
                    <TextField label="Fatigue" source="safetyScoreFatigue" />
                    <TextField label="Overall" source="safetyScoreOverall" />
                    <TextField label="Status" source="status" />
                </Datagrid>
            </List>
            <br />
        </>
    );
};

export const TripShow = () => {
    const ShowActions = () => {
        const record = useRecordContext();
        return (
            <TopToolbar>
                {record && <DeleteWithConfirmButton
                    confirmTitle="Delete Trip"
                    confirmContent={`Are you sure you want to delete Trip with KSafe ID ${record.id}? This action cannot be undone.`}
                />}
            </TopToolbar>
        );
    };

    const TripMap = () => {
        const { isLoaded } = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: Environment.GoogleMapsApiKey
        });
        const record = useRecordContext();
        if (!isLoaded) return null;
        if (!record || !record.data) return <span>Loading map...</span>;

        return (
            <GoogleMap mapContainerStyle={{width:'100%',height:600}} center={record.data.waypoints[0]} zoom={11}>
                <MarkerF key={0} position={record.data.waypoints[0]} title="Start" icon="http://maps.google.com/mapfiles/kml/paddle/go.png" />
                <MarkerF key={1} position={record.data.waypoints[record.data.waypoints.length - 1]} title="Stop" icon="http://maps.google.com/mapfiles/kml/paddle/stop.png" />
                <PolylineF 
                    path={record.data.waypoints} 
                    options={{
                        strokeColor: '#133074',
                        strokeWeight: 5,
                        fillColor: '#133074',
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        zIndex: 1
                    }}
                />
            </GoogleMap>
        );
    };

    return (
        <Show actions={<ShowActions />}>
            <TabbedShowLayout>
                <Tab label="Trip">
                    <TextField label="ID" source="id" />
                    <ReferenceField label="User" source="userId" reference="users" link="show">
                        <FunctionField render={record => `${record.firstName ?? ''} ${record.lastName ?? ''} <${record.email ?? ''}> - ${record.supportId}`} />
                    </ReferenceField>
                    <TextField label="Name" source="name" />
                    <DateField label="Started" source="startedAt" showTime locales="sv-SE" />
                    <DateField label="Ended" source="endedAt" showTime locales="sv-SE" />
                    <TextField label="Points Earned" source="pointsEarned" />
                    <FunctionField label="Distance" render={record => `${Number(record.distanceMetres).toLocaleString()} m`} />
                    <FunctionField label="Average Speed" render={record => `${record.averageSpeedKmh} km/h`} />
                    <FunctionField label="CO2 Omissions" render={record => `${Number(record.co2EmissionGrams).toLocaleString()} g`} />
                    <TextField label="Safety Score - Speed" source="safetyScoreSpeed" />
                    <TextField label="Safety Score - Distraction" source="safetyScoreDistraction" />
                    <TextField label="Safety Score - Fatigue" source="safetyScoreFatigue" />
                    <TextField label="Safety Score - Overall" source="safetyScoreOverall" />
                    <TextField label="Status" source="status" />
                </Tab>
                <Tab label="Processed Data">
                    <JsonField label="" source="data" addLabel={false} jsonString={false} reactJsonOptions={{ name: null, collapsed: false, enableClipboard: false, displayDataTypes: false }} />
                </Tab>
                <Tab label="Raw Data">
                    <JsonField label="" source="originalData" addLabel={false} jsonString={false} reactJsonOptions={{ name: null, collapsed: false, enableClipboard: false, displayDataTypes: false }} />
                </Tab>
                <Tab label="Map">
                    <TripMap />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};